import React from "react"
import Layout from "components/Layout/LayoutNewYork"
import PageHeader from "components/shared/PageHeader"
import styled from "styled-components";
import {Container} from 'reactstrap';

const Content = styled(Container)`
    position: relative;
    margin: 0 auto;
    text-align: left;
`

const TermsPage = () => (
    <Layout
        title="Terms and Conditions | New York | BTTF"
        booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
    >
        <PageHeader city="new-york" title="Terms and Conditions" />
        <Content className="my-3 my-md-5">
            <p>
                <strong>Privacy</strong>
            </p>
            <p>
                Our Privacy Policy forms part of these terms and conditions and together
                they form a binding agreement governing the use of this website.
            </p>
            <p>
                <strong>Variation of terms and conditions</strong>
            </p>
            <p>
                We reserve the right to change these terms and conditions from time to
                time, and the amended terms will be posted on this website. Any revised
                terms shall take effect as at the date when the change is made to this
                website.
            </p>
            <p>
                <strong>Materials in site and software rights</strong>
            </p>
            <p>
                All copyrights, trademarks, patents, design rights, and other intellectual
                property rights relating to this website (including, but not limited to,
                the underlying software, the design, graphics, layout, feel, and structure
                of our websites) will be and remain the sole property of us and our
                licensors. You may view, use, download, and store the material on this
                website for personal and research use only. Commercial use is not
                permitted. Redistributing, republishing, copying, adapting or otherwise
                making material on this website available to third parties is prohibited.
                You may view, use, download, and store the material on this website for
                personal and research use only. Commercial use is not permitted.
                Redistributing, republishing, copying, adapting or otherwise making
                material on this website available to third parties is prohibited. The
                information in this website is given in good faith and for general
                information and interest only. It is subject to change without notice. We
                make every effort to ensure that the information on our websites is correct
                but we cannot guarantee that it is 100% free of inaccuracies, errors and
                omissions.
            </p>
            <p>
                <strong>Our liability</strong>
            </p>
            <p>
                The use of the website and the materials contained in it are entirely at
                your own risk. We shall not be liable for any losses or damages, whether
                direct or indirect, consequential or otherwise that you may suffer as a
                result of your use of the website, including but not limited to computer
                service or system failure, access delays or interruption, data non-delivery
                or mis-delivery, computer viruses or other harmful components, breaches of
                security or unauthorized use of the system arising from “hacking” or
                otherwise or your reliance on the information contained on the website.
                These terms and conditions do not exclude our liability (if any) to you for
                personal injury or death resulting from our negligence, or for any matter
                which it would be illegal for us to attempt to exclude its liability and do
                not affect your statutory rights. Our websites may contain links to other
                sites, which are outside our control and are not covered by these terms and
                conditions. If you access other sites using the links provided, we cannot
                be responsible for the content of those sites or for the way in which they
                deal with you or use any information they may acquire about you.
                Arrangements made between you and any third party named or referred to on
                the website are entirely at your sole risk and responsibility.
            </p>
            <p>
                <strong>Governing law</strong>
            </p>
            <p>
                This Agreement is deemed to be made in England and is subject to English
                law and the exclusive jurisdiction of the English courts.
            </p>
        </Content>
    </Layout>
)

export default TermsPage
